@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.secondCol {
  margin-top: 24px;
  margin-bottom: 24px;
  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: $primary-color;
    font-weight: bold;
    font-family: 'Roboto Condensed', sans-serif;
    margin: 24px auto;
    text-align: center;
    @include breakpoint-up(md) {
      text-align: left;
      font-size: 32px;
      line-height: 46px;
      margin-bottom: 0px;
      margin-top: 1rem;
    }
  }
  svg {
    max-width: 100%;
  }
  .slide {
    outline: none;
    .list {
      list-style: none;
      margin-top: 10px;
      padding-left: 15px;
      @include breakpoint-up(md) {
        padding-left: 30px;
      }
      .listItem {
        margin: 12px 0;
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.3rem;
        color: #333;
        &::before {
          content: '\2022';
          display: inline-block;
          color: $primary-color;
          width: 1rem;
          margin-left: -16px;
          font-size: 1.5rem;
          vertical-align: text-bottom;
        }
        img {
          margin: 20px auto 10px;
          max-height: 215px;
          @include breakpoint-up(md) {
            max-height: 300px;
          }
        }
      }
    }
  }
}

.btnWrapper {
  button {
    width: 100%;
  }
}
