@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.immazing {
  color: $gray-dark-color;
  white-space: pre-line;
  cursor: default;
  margin-bottom: 16px;
  @include breakpoint-up(md) {
    margin-bottom: 0;
  }
  .subTitle {
    color: $gray-dark-color;
    span {
      display: inline-flex;
      svg {
        margin-left: 5px;
        width: 30%;
      }
    }
  }
  .vw {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: black;
    @include breakpoint-up(md) {
      font-size: 27px;
      line-height: 32px;
    }
  }
  .vw_lwr {
    color: $success-color;
  }
  .vw_upr {
    color: $yellow-color;
  }
}

.chartContainer {
  text-align: center;
  svg {
    margin-left: -10px;
    padding-top: 20px;
    @include breakpoint-up(md) {
      margin-left: 0;
    }
    text:not(:only-child) {
      &:first-child,
      &:last-child {
        display: none;
      }
    }
  }
}

.btnWrapper {
  text-align: right;
  button {
    width: 100%;
    @include breakpoint-up(md) {
      width: initial;
    }
  }
}

.card {
  background-color: white;
  padding: 20px;
  border-radius: 16px;
}
