@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.container {
  .inputWithBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .button {
      border-width: initial;
      border-style: none;
      border-image: initial;
      outline: none;
      cursor: pointer;
    }
    .searchFieldWrapper {
      position: relative;
      padding: 0;
      margin-bottom: 0;
    }
    .icon {
      position: absolute;
      left: 10px;
      bottom: 9px;
    }
    .filtersButton {
      @extend .button;
      background-color: white;
      padding: 10px 15px;
      border-radius: 6px;
      margin-left: 5px;
    }
    .btnClearAllFilters {
      @extend .button;
      position: absolute;
      bottom: -40px;
      right: -5px;
      background: transparent;
      text-decoration: underline;
      color: $second-primary-color;
    }
  }
  .filtersContainer {
    margin: 20px 0 10px;
    color: $primary-color;
    .filter {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .filterItemBox {
        width: fit-content;
        border-left: 3px solid $second-primary-color;
        padding: 13px 30px 13px 15px;
        border-radius: 6px;
        background-color: white;
        position: relative;
        .deleteFilter {
          position: absolute;
          top: calc(50% - 10px);
          right: 5px;
          cursor: pointer;
        }
        margin: 5px 8px 5px 0;
      }
    }
  }
}

.optionWrapper {
  display: flex;
  align-items: center;
  .avatar {
    width: 30px;
    height: 30px;
    background-size: cover;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
  }
  .defaultAvatar {
    @extend .avatar;
    background-color: #f7f7f7;
  }
}
