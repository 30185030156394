@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.chartContainer {
  position: relative;
  h1 {
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    @include breakpoint-up(md) {
      text-align: center;
    }
  }
  margin-bottom: 15px;
}
