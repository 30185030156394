@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.steps {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @include breakpoint-up(lg) {
    margin-top: 0;
  }
  .step {
    margin-bottom: 16px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 14px;
      background-color: $second-primary-light-color;
      border-radius: 6px;
      .notCompletedIcon rect {
        fill: $gray-dark-color;
      }
      .headerItem {
        display: flex;
        align-items: center;
        svg {
          margin-right: 8px;
        }
        h3 {
          margin: 0;
          font-weight: bold;
          color: $primary-color;
        }
      }
    }
    .body {
      margin-left: 20px;
      margin-right: 14px;
      @include breakpoint-up(md) {
        margin-left: 60px;
      }
      .description {
        margin-top: 8px;
        color: $primary-color;
      }
      .disabledLink {
        pointer-events: none;
      }
      a[disabled] {
        pointer-events: none;
      }
      .buttonsWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        & > div, a, button {
          width: 100%;
        }
        .proceedBtn {
          margin-bottom: 10px;
        }
        @include breakpoint-up(md) {
          & > div, a, button {
            width: initial;
          }
          .proceedBtn {
            margin-bottom: 0;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
