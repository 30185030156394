@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.warningMessage {
  margin-top: 20px;
  .title {
    margin: 0 auto;
  }
  @include breakpoint-up(md) {
    margin-top: 5%;
  }
  .wrapper {
    background-color: white;
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    .message {
      font-weight: normal;
      font-size: 20px;
      text-align: center;
      color: $gray-dark-color;
      @include breakpoint-up(md) {
        font-size: 32px;
      }
    }
    button {
      width: 100%;
    }
    @include breakpoint-up(md) {
      min-width: 500px;
      button {
        width: initial;
      }
    }
  }
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  div {
    width: 100%;
    button {
      width: 100%;
      svg path {
        fill: $second-primary-color;
      }
    }
  }
  @include breakpoint-up(md) {
    div {
      text-align: right;
      button {
        width: initial;
      }
    }
  }
}
