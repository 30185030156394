@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.form {
  color: $gray;
  .btnWrapper {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: right;
    button {
      width: 100%;
      margin: 10px 0;
    }
  }
  .privacyUrlText {
    font-size: 14px;
    a {
      text-decoration: none;
      color: $second-primary-color;
    }
  }
  .switch {
    margin-top: 15px;
    font-size: 14px;

    .switchBtn {
      color: $second-primary-color !important;
      text-decoration: none;
    }
  }
}
.divider {
  display: flex;
  align-items: center;
  margin: 15px 0;
  .line {
    width: 100%;
    height: 1px;
    background-color: $silver;
    &:first-of-type {
      margin-right: 10px;
      border-radius: 2px 0 0 2px;
    }
    &:last-of-type {
      margin-left: 10px;
      border-radius: 0 2px 2px 0;
    }
  }
  .text {
    color: $gray;
    font-size: 15px;
  }
}
.socialLoginContainer {
  .label {
    color: $gray;
    font-weight: 500;
    pointer-events: none;
    font-size: 15px;
  }
  .socialButtonsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .googleLoginBtn {
      background-color: transparent !important;
      box-shadow: none !important;
      outline: none;
      div {
        border-radius: 50% !important;
        height: 40px;
        width: 40px;
        margin: 0 !important;
        box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
        transition: all 0.3s;
        &:hover {
          transition: all 0.3s;
          background-color: $second-primary-light-color !important;
        }
      }
      span {
        padding: 0 !important;
      }
    }
    .facebookLoginBtn {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border: none;
      outline: none;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        transition: all 0.3s;
        background-color: $second-primary-light-color !important;
      }
      i:before {
        color: #4c68ba;
      }
    }
  }
}
