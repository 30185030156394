$primary-color: #1c145f;
$second-primary-color: #6b3fdc;
$second-primary-light-color: #e2e3f6;
$violet-red: #f2397b;
$gray: #6c6666;
$gray-light: #e0e0e0;
$bright-gray: #eaeaea;
$silver: #bdbdbd;
$gray-dark-color: #5f5f5f;
$yellow-color: #facd37;
$background-color: #f7f8f9;
$navbar-height: 72px;
$mobile-navbar-height: 60px;
$success-color: #3caa38;
$pastel-green-color: #a3c613;
$font-family: 'Montserrat', sans-serif;
$red: #eb2726;
$green: #40c789;

$primary-light-color: lighten($primary-color, 5);
$gray-light-color: #e0e0e0;
$white-smoke-color: #f2f2f2;
$whisper-grey: #f7f7f7;
$sidebar-width: 240px;
$footer-height: 35px;
$footer-mobile-height: 60px;
$text-color: black;
$text-inverted-color: white;

// Breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

$z-index-loading-overlay: 10;
$z-index-navbar: 10;
$z-index-footer: 5;
$z-index-modal: 30;
$z-index-sidebar-overlay: 7;
$z-index-sidebar: 8;

$spacer: 20px;

// the :export directive is the magic sauce for webpack
:export {
  primaryColor: $primary-color;
  secondPrimaryColor: $second-primary-color;
  silverColor: $silver;
  backgroundColor: $background-color;
  navbarHeight: $navbar-height;
  primaryLightColor: $second-primary-light-color;
  dangerColor: $violet-red;
  redColor: $red;
  greenColor: $green;

  successColor: $success-color;
  yellowColor: $yellow-color;
  whiteSmokeColor: $white-smoke-color;
  grayColor: $gray-dark-color;
  grayLightColor: $gray-light-color;
  grayDarkColor: $gray-dark-color;
  whisperGrey: $whisper-grey;
  pastelGreenColor: $pastel-green-color;
}
