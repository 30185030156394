@import 'styles/breakpoints.scss';
@import 'styles/variables.scss';

p {
  margin-bottom: 1em;
  font-size: 14px;
  @include breakpoint-up(md) {
    font-size: 16px;
  }
}

a {
  color: $primary-color;
}

img {
  max-width: 100%;
  height: auto;
}

ul,
ol {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

// heading styles
h1 {
  word-break: break-word;
  font-size: 1.4em;
  font-weight: 500;
  @include breakpoint-up(md) {
    font-size: 2em;
  }
}
h2 {
  word-break: break-word;
  font-size: 1.2em;
  @include breakpoint-up(md) {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000;
  }
}
h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #333333;
  margin: 16px 0px;
}
h4 {
  font-weight: 500;
  font-size: 18px;
}

.link {
  font-weight: 400;
  color: $primary-color;
  background-color: transparent;
  padding: 0.375em 0;
  font-size: 1em;
  text-decoration: none;
  border: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
}

.sticky-img-box {
  position: sticky;
  top: 150px;
  margin: 20px 0;
}

.muted {
  color: $gray-dark-color;
}

// .rc-tooltip styles
.rc-tooltip {
  max-width: 330px;
  background-color: transparent;
}

.rc-tooltip-inner {
  border-radius: 10px;
  border-color: $primary-color;
}

.rc-tooltip-arrow {
  border-top-color: $primary-color !important;
}
.slick-dots li button:before {
  color: $gray-dark-color;
  font-size: 10px;
}
.slick-dots li.slick-active button:before {
  color: $primary-color;
}

// rc-slider styles
.rc-slider-mark {
  margin-top: 13px;
  margin-left: 2px;
  width: 99%;
}

// rc-checkbox styles
.rc-checkbox-checked .rc-checkbox-inner {
  border-color: $second-primary-color;
  background-color: $second-primary-color;
}

.rc-checkbox-checked:hover .rc-checkbox-inner {
  border-color: $primary-color;
}

.rc-checkbox:hover .rc-checkbox-inner,
.rc-checkbox-input:focus + .rc-checkbox-inner {
  border-color: $primary-color;
}

.rc-checkbox-checked .rc-checkbox-inner:after {
  border: 2px solid white;
  border-top: 0;
  border-left: 0;
}

// datepicker styles
.datepicker-wrapper {
  margin-top: 10px;
  border-bottom: 1px solid #adadad;
}
.react-datepicker-wrapper {
  display: block;
}

// location search input styles
.suggestion-item {
  padding: 10px 5px;
  background-color: white;
  padding: 10px;
  color: $primary-color;
  cursor: pointer;
}

.suggestion-item--active {
  padding: 10px 5px;
  background-color: $second-primary-light-color;
  padding: 10px;
  cursor: pointer;
}

// slider styles
.rec {
  & > button {
    padding: 0;
    box-shadow: none;
    background-color: $gray-light-color;
  }
}

.rec-dot_active {
  background-color: $primary-color !important;
}

// input styles
.field-wrapper {
  position: relative;
  padding-bottom: 25px;
  padding-top: 25px;
  margin-bottom: 10px;
  width: 100%;
  label {
    position: absolute;
    display: flex;
    align-items: flex-end;
    color: $primary-color;
    height: 32px;
    left: 0px;
    top: -17px;
    font-weight: 500;
    pointer-events: none;
  }
}
input {
  border: none;
  -webkit-appearance: none;
  width: 100%;
  padding: 14px 16px;
  outline: none;
  background: white;
  font-family: $font-family;
  box-shadow: none;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: $primary-color;
  border-radius: 6px;
  box-sizing: border-box;
}

.default-input {
  border: 1px solid $bright-gray;
}

.default-input:focus {
  border: 1px solid $second-primary-color;
  outline: none;
}

label {
  font-size: 14px;
  color: $gray;
  display: block;
  cursor: auto;
  text-align: left;
  @include breakpoint-up(lg) {
    font-size: 15px;
  }
}

.field-with-big-label {
  padding-top: 40px !important;
  label {
    top: 0px !important;
  }
}

.error-input {
  border: 1px solid $violet-red;
}

.disabled-input {
  cursor: not-allowed;
}

.error-text {
  font-size: 13px;
  color: #dc3545;
  position: absolute;
  margin-top: 3px;
}

.error-wrapper {
  padding: 23px;
  p {
    font-size: 14px;
    position: static;
  }
}

.rc-table-row {
  cursor: pointer;
}

// react-collapsible styles
.collapsible-trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-right: 2px solid $primary-color;
    border-top: 2px solid $primary-color;
    bottom: 15px;
    left: 47%;
    transform: rotate(135deg);
    display: inline;
    transition: all 0.4s linear;
    @include breakpoint-up(md) {
      width: 6px;
      height: 6px;
      top: 45%;
      left: -12px;
      transform: rotate(45deg);
    }
  }

  &.is-open {
    &:before {
      transform: rotate(-45deg);
      transition: all 0.4s linear;
      @include breakpoint-up(md) {
        transform: rotate(135deg);
      }
    }
  }
  @include breakpoint-up(md) {
    position: relative;
  }
}

// dropdown
.dropdown-menu {
  display: flex;
  align-items: center;
  width: 240px;
  height: 50px;
  background-color: white;
  border-radius: 10px;
  list-style-type: none;
  box-shadow: 6px 6px 48px rgba(0, 0, 0, 0.08);
  padding: 0;
  .menu-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    button {
      width: 100%;
      padding: 5px 30px 5px 5px;
      box-shadow: none;
      background-color: transparent;
      border-radius: 0;
      &:hover {
        background-color: $second-primary-light-color;
      }
    }
  }
}

.card {
  padding: 20px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
}