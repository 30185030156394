@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.actionBar {
  display: flex;
}

.financingBtnWrapper {
  position: relative;
  margin: 60px 0 30px;
  .finishFlagIcon {
    position: absolute;
    top: -65px;
    right: 0px;
    width: 80px;
    height: 80px;
  }
  .financingRequestButton {
    padding: 7px 20px;
    width: 100%;
    z-index: 1;
  }
  @include breakpoint-up(lg) {
    margin-top: 40px;
    .finishFlagIcon {
      top: -58px;
      right: -43px;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    text-align: left;
  }
}
.chevronSvg {
  transform: rotate(90deg);
  transition: transform 0.3s ease-in;
}

.activeChevronSvg {
  transform: rotate(-90deg);
  transition: transform 0.3s ease-out;
}
.trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    path {
      fill: $primary-color;
    }
  }
}

.infoContainer {
  background-color: white;
  text-align: justify;
  white-space: pre-line;
  line-height: 1.2rem;
  margin: 16px 0;
  padding: 20px;
  border-radius: 6px;
  h2 {
    margin-top: 0;
  }
  @include breakpoint-up(md) {
    line-height: 1.8rem;
    text-align: left;
  }
}
