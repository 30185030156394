@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.downloads {
  background-color: white;
  padding: 24px 16px;
  border-radius: 10px;
  text-align: center;
  margin: 16px 0;
  h3 {
    color: $primary-color;
  }
  .downloadButton {
    button {
      width: 100%;
    }
    @include breakpoint-up(md) {
      button {
        width: initial;
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }
    @include breakpoint-up(lg) {
      button {
        width: 100%;
        &:first-of-type {
          margin-right: 0;
        }
      }
    }
    &:first-of-type button {
      margin-bottom: 12px;
    }
  }
}
