@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.td {
  font-weight: 300;
}

.actionBar {
  display: flex;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    text-align: left;
  }
  margin: 8px 0 16px;
}

.tablesWrapper {
  background-color: white;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  padding: 10px 0 10px 15px;
}

.divider {
  width: 93%;
  margin: 15px 10px;
  height: 1px;
  background-color: #f6f2ff;
}

.btnWrapper {
  margin: 10px 0;
  text-align: right;
  display: flex;
  flex-direction: column;
  button {
    width: 100%;
  }
  a:last-child {
    margin-top: 10px;
  }
  @include breakpoint-up(md) {
    flex-direction: row;
    justify-content: space-between;
    a:last-child {
      margin-top: 0px;
      margin-left: 20px;
    }
    button {
      width: initial;
    }
  }
}

.pdfBtn {
  svg path {
    fill: $second-primary-color;
  }
}

.alertPotentialValues {
  p b, b {
    color: $success-color;
  }
}
