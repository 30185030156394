@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.category {
  .card {
    position: relative;
    border: 1px solid $white-smoke-color;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    background-color: white;
    padding: 10px;
    @include breakpoint-up(md) {
      padding: 20px;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        margin: 0;
      }
      .description {
        margin-bottom: 20px;
      }
      .price {
        margin: 0;
        white-space: nowrap;
      }
    }
  }
}
