@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.container {
  .header {
    margin-bottom: 15px;
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-weight: 600;
        font-size: 16px;
        color: $primary-color;
        margin: 8px 0;
        @include breakpoint-up(md) {
          font-size: 24px;
        }
      }
      b {
        font-size: 16px;
        color: $second-primary-color;
        @include breakpoint-up(md) {
          font-size: 20px;
        }
      }
    }
  }
  .totalSaved {
    color: $green;
  }
  .totalOverspent {
    color: $red;
  }
}
