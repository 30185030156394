@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.container {
  box-sizing: border-box;
  flex-wrap: wrap;
  margin: 15px 0;
  &:first-child > input {
    margin-left: 0px;
  }
}
.input {
  min-width: 33px;
  min-height: 33px;
  width: 2rem !important;
  height: 2rem;
  border-radius: 15%;
  margin: 3px;
  padding: 0;
  background-color: white;
  border: 1px solid grey;
  @include breakpoint-up(md) {
    width: 40px !important;
    height: 40px;
    margin: 8px;
  }
}
.btn {
  padding: 15px 20px;
  div:first-child {
    padding: 0 !important;
  }
}
.focusInput {
  border: 1px solid $second-primary-color;
}
