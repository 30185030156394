@import 'styles/variables.scss';

.switchWrapper {
  display: flex;
  align-items: center;
  top: 8px;
  margin-bottom: 20px !important;
  padding-top: 0 !important;
}
.switchLabel {
  color: $gray-dark-color;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  font-size: 13px;
  position: static !important;
  height: auto !important;
  order: 1;
}
.switch {
  margin-right: 8px;
  div {
    border: 1px solid $silver;
  }
}

.switchOn div {
  border: 1px solid $second-primary-color;
}

.greenSwitchOn div {
  border: 1px solid $success-color;
}