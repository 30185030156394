@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.card {
  background-color: white;
  padding: 20px 20px 10px;
  border-radius: 6px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.03);
  margin-bottom: 15px;
  position: relative;
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cardHeader {
      margin-bottom: 15px;
      .cartTitle {
        margin: 0 0 8px;
      }
      .cardEquipmentType {
        color: $gray-dark-color;
        display: block;
        font-size: 14px;
        margin: 3px 0;
      }
      .cardDescription {
        display: block;
        color: $gray-dark-color;
        font-size: 12px;
        margin: 3px 0;
      }
    }
    .cardBody {
      .cardPriceText {
        font-size: 16px;
      }
      .cardCurrencyValue {
        font-size: 12px;
        color: $gray-dark-color;
      }
    }
  }
  .cardFooter {
    margin-top: 12px;
    font-size: 16px;
    color: $primary-color;
    .recordPayment {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 25px;
      div:first-child {
        margin-right: 10px;
      }
    }
  }
}

.contentOuter {
  margin: 0 -8px;
}

.paymentsContainer {
  margin-bottom: 35px;
  padding-left: 8px;
  padding-right: 8px;
}
