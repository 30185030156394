@import 'styles/variables.scss';

.spendingsTable {
  border-collapse: collapse;
  td,
  th {
    padding: 5px 10px !important;
  }

  tbody tr {
    font-weight: bold;
    width: 100%;
    vertical-align: middle;
    &:last-child {
      td {
        border-bottom: 1px solid transparent !important;
      }
    }
  }

  td {
    border: 1px solid transparent !important;
    border-bottom: 1px solid #f6f2ff !important;
    h4 {
      color: $primary-color;
      margin: 7px 0;
      text-transform: uppercase;
    }
    small {
      font-size: 12px;
      display: block;
      color: $silver;
      margin: 5px 0;
      font-weight: normal;
    }
    .calculation {
      font-size: 16px;
    }
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    &:first-child {
      margin-right: 5px;
    }
  }
  .plusIcon {
    path {
      fill: $second-primary-color;
    }
  }
}

.chevronIcon {
  transform: rotate(0deg);
  transition: transform 0.3s linear;
  path {
    fill: $primary-color;
  }
}

.activeChevronIcon {
  transform: rotate(90deg);
  transition: transform 0.3s linear;
}

.paymentsTable {
  padding-left: 45px;
  thead {
    th {
      background-color: #f4f4f4 !important;
      border: none !important;
      text-align: left !important;
    }
  }
  tr {
    td,
    th {
      font-weight: normal;
      padding: 10px;
      color: $primary-color;
      &:first-child {
        width: 22%;
      }
      &:nth-child(2) {
        width: 13%;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(4) {
        width: 20%;
      }
      &:last-child {
        width: 20%;
      }
    }
  }
}

.topTableWrapper {
  background-color: white;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  padding: 10px 24px;
  table {
    font-weight: bold;
    td {
      vertical-align: bottom;
      h4 {
        color: $primary-color;
        margin: 7px 0;
        text-transform: uppercase;
      }
      small {
        font-size: 12px;
        display: block;
        color: $silver;
        margin: 5px 0;
        font-weight: normal;
      }
      .calculation {
        font-size: 16px;
      }
      border: 1px solid transparent !important;
    }
  }
}

.saved {
  font-size: 13px;
  margin-top: 5px;
  color: $green;
}
.overspent {
  font-size: 13px;
  margin-top: 5px;
  color: $red;
}
.deviation {
  margin-top: 5px;
}
