@import 'styles/variables';
@import 'styles/breakpoints.scss';

.mapContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  border-radius: 12px;
  .toggleButtons {
    margin: auto;
    display: flex;
    justify-content: center;
    order: -1;
    @include breakpoint-up(md) {
      width: 80%;
      margin: 15px auto 0;
      order: 0;
    }
    .activeButton {
      align-items: center;
      svg path {
        fill: $primary-color;
        stroke: $primary-color;
      }
    }
  }


  .buttonText {
    margin: auto;
  }
  .map {
    position: static !important;
  }
}

.locationSearchFieldWrapper {
  padding: 50px 0 25px !important;
  .locationSearchInput {
    text-align: left;
    box-shadow: 0px 0px 10px 2px rgba(107, 63, 220, 0.5);
  }
  .locationSearchLabel {
    text-align: start;
    color: $second-primary-color;
    top: 10px;
  }
}
