@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.buttonWrapper {
  button {
    width: 100%;
  }
  @include breakpoint-up(md) {
    text-align: right;
    button {
      width: initial;
    }
  }
}

.uploadImgButton {
  text-align: right;
}

.fieldContainer {
  display: flex;
  align-self: center;
  justify-content: flex-start;
  .images {
    margin-left: 15px;
    display: flex;
    flex-wrap: wrap;
    .imgBox {
      position: relative;
      background-size: cover;
      object-fit: cover;
      border-radius: 50%;
      width: 55px;
      height: 55px;
      margin: 5px 10px;
      border: 1px solid #ebebeb;
      cursor: pointer;
      &:hover {
        box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
      }
    }
    .removeBtn {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $violet-red;
      position: absolute;
      cursor: pointer;
      top: 0px;
      right: 5px;
      path {
        fill: white;
      }
      &:hover {
        box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.dropzone {
  min-width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #f7f7f7;
  border: 1px solid #ebebeb;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 2px;
    margin-bottom: 2px;
  }
}

.selectPayer {
  label {
    pointer-events: all !important;
  }
}
.selectPayerLabel {
  white-space: pre-line;
  .createNewPayerLabel {
    cursor: pointer;
    border-width: initial;
    border-style: none;
    border-image: initial;
    outline: none;
    background-color: transparent;
    color: $primary-color;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.optionWrapper {
  display: flex;
  align-items: center;
  .avatar {
    width: 30px;
    height: 30px;
    background-size: cover;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
  }
  .defaultAvatar {
    @extend .avatar;
    background-color: #f7f7f7;
  }
}

.imgBox {
  text-align: center;
  img {
    max-height: 60vh;
  }
}
