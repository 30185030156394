@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.resultTable {
  td {
    padding: 5px !important;
    @include breakpoint-up(md) {
      padding: 10px !important;
    }
    &:last-child {
      white-space: normal !important;
      text-align: left !important;
    }
  }
}

.showAllCalculations {
  cursor: pointer;
  color: $primary-color;
  margin: 16px 0 16px 8px;
}

.pageNameCol {
  margin: 20px 0;
  h1 {
    text-align: left;
  }
  @include breakpoint-up(md) {
    margin: 0;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  button:first-child {
    margin-bottom: 5px;
  }
  @include breakpoint-up(md) {
    flex-wrap: nowrap;
    button:first-child {
      margin-right: 5px;
      margin-bottom: 0;
    }
  }
}

.btnsWrapper {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  @include breakpoint-up(md) {
    flex-direction: row;
  }
  .btnRenovateHouse {
    min-width: 250px;
    width: 100%;
    margin-bottom: 16px;
    @include breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

.actionBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  :not(:last-child) {
    margin-right: 5px;
  }
}

@mixin pulse($bg) {
  $name: inline-#{unique-id()};

  @-webkit-keyframes #{$name} {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 $bg;
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 11px rgba(255, 82, 82, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }
  @-moz-keyframes #{$name} {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 $bg;
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 11px rgba(255, 82, 82, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }
  @keyframes #{$name} {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 $bg;
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 11px rgba(255, 82, 82, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }

  animation: $name 2s infinite;
}

.chart {
  margin-bottom: 30px;

  .a-plus {
    background-color: #31a54f;
    color: white;
    @include pulse(darken(#31a54f, 2%));
  }
  .a {
    background-color: #4cb848;
    color: white;
    @include pulse(darken(#4cb848, 2%));
  }
  .b {
    background-color: #b8ff02;
    color: #31a54f;
    @include pulse(darken(#b8ff02, 2%));
  }
  .c {
    background-color: #faff00;
    color: #2771bb;
    @include pulse(darken(#fbff00, 2%));
  }
  .d {
    background-color: #fcfe07;
    color: #2771bb;
    @include pulse(darken(#fcfe07, 2%));
  }
  .e {
    background-color: #fcf301;
    color: #2771bb;
    @include pulse(darken(#fcf301, 2%));
  }
  .f {
    background-color: #fbd807;
    color: white;
    @include pulse(darken(#fbd807, 2%));
  }
  .g {
    background-color: #f67a07;
    color: white;
    @include pulse(darken(#f67a07, 2%));
  }
  .h {
    background-color: #f53909;
    color: white;
    @include pulse(darken(#f53909, 2%));
  }
  .circle {
    transform: scale(1);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    padding: 20px;
    @include breakpoint-up(md) {
      width: 220px;
      height: 220px;
    }
    .labelInsideCircle {
      font-size: 16px;
      @include breakpoint-up(md) {
        font-size: 24px;
      }
    }
  }
  .legend {
    .legendItem {
      margin: 8px 0;
      color: $primary-color;
    }
  }
}

.beforeAndAfterTable {
  th,
  td {
    text-align: center !important;
    font-weight: normal;
    &:first-child,
    &:last-child {
      text-align: left !important;
    }
  }
  th {
    padding: 18px 15px !important;
    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  small + div {
    margin-top: 8px;
    font-size: 18px;
    color: $primary-color;
    font-weight: 500;
  }
}

.tablesWrapper {
  background-color: white;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  padding: 10px 8px;
  @include breakpoint-up(md) {
    padding: 10px 15px;
  }
}
