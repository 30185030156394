@import 'styles/variables.scss';

.card {
  margin-top: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: space-between;
  h4 {
    margin: 0 0 10px;
    font-weight: bold;
  }
  small {
    color: $gray-dark-color;
    font-size: 13px;
  }
}

.totals {
  font-size: 13px;
  margin-top: 5px;
  & > div {
    margin: 3px 0;
  }
  .saved {
    color: $green;
  }
  .overspent {
    color: $red;
  }
}
