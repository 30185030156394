@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.tableWrapper {
  background-color: white;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  padding: 10px;
}
.secondCol {
  order: 1;
  @include breakpoint-up(md) {
    order: 0;
  }
}
