@import 'styles/variables.scss';

.alert {
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 15px;
  min-height: 50px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
  text-align: left;
}

.danger {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

.primary {
  background-color: white;
  border-left: 4px solid $violet-red;
  color: $primary-color;
  white-space: pre-line;
  b {
    color: $violet-red;
    line-height: 24px;
  }
  p {
    margin: 0;
    line-height: 24px;
  }
}

.success {
  b {
    color: #204e79;
  }
  background-color: #a9d18e;
  color: #3b3a39;
}