@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.triggeredContent {
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: $second-primary-light-color;
  }
  svg {
    transform: rotate(90deg);
    path {
      fill: $second-primary-color;
    }
  }
  
}

.menuItem {
  border: none;
}

.dropdownContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 0 10px 0 5px;
}

.chevronIcon {
  height: 20;
  min-width: 15px;
  transform: rotate(-90deg);
  transition: transform 0.3s linear;
}

.activeChevronIcon {
  transform: rotate(0);
  transition: transform 0.3s linear;
}

.chooseProjectDropdown {
  width: 280px;
  background-color: white;
  list-style-type: none;
  padding: 0;
  font-size: 16px;
  padding: 10px 0;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  outline: none;
  border: none;
  font-family: $font-family;
  .menuItem {
    & > div {
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      font-size: 1.1rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    cursor: pointer;
    color: $primary-color;
    padding: 7px 17px;
    &:hover {
      background-color: $second-primary-light-color;
    }
  }
}

.list {
  flex-wrap: wrap;
}

.card {
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5px 15px;
  font-size: 14px;
  background-color: white;
  max-height: 250px;
  min-height: 200px;
  margin: 15px 0;
  outline: none;
  border: none;
  border-width: initial;
  border-style: none;
  border-image: initial;
  overflow: hidden;
  @include breakpoint-up(md) {
    border-radius: 15px;
    padding: 15px 25px;
    font-size: 16px;
  }
  &:hover {
    box-shadow: 4px 6px 12px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    .paymentName {
      color: $second-primary-color;
    }
  }
  .cardHeader {
    position: relative;
    .paymentName {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 16px;
      display: -webkit-box;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .moreActionsBtn {
      position: absolute;
      right: -5px;
      top: -9px;
    }
    small {
      color: $gray;
    }
  }
  .cardBody {
    margin: 15px 0;
    .cardBodyCol {
      small {
        margin: 5px 0;
        color: $second-primary-color;
      }
      display: flex;
      flex-direction: column;
    }
    .notes {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.projectName {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  @include breakpoint-up(md) {
    -webkit-line-clamp: 1;
  }
}
