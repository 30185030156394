@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.subItem {
  position: relative;
  border: 1px solid $white-smoke-color;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  background-color: white;
  padding: 10px;
  @include breakpoint-up(md) {
    padding: 20px;
  }
  .title {
    margin: 0;
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
}
