@import 'styles/variables.scss';

.heading {
  margin-bottom: -35px;
  text-align: left;
  font-size: 16px;
  .label {
    font-size: 16px;
  }
  .description {
    color: $gray;
    font-size: 13px;
    margin-bottom: 1.3em;
    min-height: 13px;
    text-align: left;
  }
}
