@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.fieldsContainer {
  @include breakpoint-up(md) {
    display: flex;
    align-items: center;
    div:first-child {
      margin-right: 15px;
    }
  }
}
.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  margin-bottom: 25px;
  background-color: #f7f7f7;
  border: 1px solid #ebebeb;
  cursor: pointer;
  outline: none;
  svg {
    margin-bottom: 3px;
    margin-left: 3px;
  }
}
