@import 'styles/variables.scss';

.triggeredContent {
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  svg {
    transform: rotate(90deg);
    path {
      fill: $second-primary-color;
    }
  }
}

.menu {
  background-color: white;
  border-radius: 10px;
  list-style-type: none;
  box-shadow: 6px 6px 48px rgba(0, 0, 0, 0.08);
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  outline: none;
  .menuItemBtn {
    width: 100%;
    button {
      width: 100%;
      min-height: 40px;
      padding: 5px;
      box-shadow: none;
      border: none;
    }
  }
}
