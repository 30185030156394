@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.personalContribution {
  display: flex;
  justify-content: space-between;
  div:last-child {
    padding-left: 30px;
  }
}
.disclaimer {
  margin-top: 24px;
}
.td {
  font-weight: 300;
}
.actionBar {
  display: flex;
  justify-content: flex-end;
  :not(:last-child) {
    margin-right: 5px;
  }
}

.tablesWrapper {
  background-color: white;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  padding: 10px 15px;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 24px 0;
  a,
  button {
    width: 100%;
  }
  button {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  @include breakpoint-up(md) {
    margin: 24px 0;
    a,
    button {
      width: initial;
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
}

.actionButton {
  padding: 8px;
  font-size: 14px;
  border: 1px solid $success-color;
  background-color: $success-color;
  cursor: pointer;
  transition-duration: 0.3s;
  border-width: initial;
  border-style: none;
  border-image: initial;
  outline: 0px;
  border-radius: 6px;
  font-weight: normal;
  position: relative;
  box-shadow: none;
  max-width: 40px;
  max-height: 40px;
  &:disabled {
    opacity: 0.8;
    cursor: default;
  }
  svg path {
    fill: $yellow-color;
  }
}

.totalCosts {
  h2 {
    margin: 0;
  }
}

.greenBackground {
  background-color: $success-color;
  h2 {
    color: white;
  }
}

.chartContainer {
  margin-left: auto;
  background-color: white;
  padding: 20px 15px;
  border-radius: 6px;
  margin-bottom: 16px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
}
