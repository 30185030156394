@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.row {
  padding: 0;
  p {
    text-align: justify;
  }
  @include breakpoint-up(md) {
    padding: 20px;
  }
  h3 {
    margin-top: 0;
  }
}
.showModalBtn {
  margin: 0 0 -4px 5px;
  padding: 0 !important;
}
