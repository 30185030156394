@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.donutChart {
  color: $primary-color;
  li {
    margin-bottom: 5px;
    font-size: 14px;
    display: block !important;
  }
}
