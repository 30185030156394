@import 'styles/variables.scss';

.textArea {
  width: 100%;
  height: 340px;
  background: white;
  resize: none;
  padding: 14px 16px;
  outline: none;
  border: 1px solid $gray-light-color;
  border-radius: 8px;
  box-sizing: border-box;
  color: $primary-color;
  line-height: 1.4rem;
  -webkit-appearance: none;
}
