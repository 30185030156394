@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';
@import 'styles/helpers.scss';

.btn {
  padding: 0.8em 1.35em;
  font-size: 14px;
  color: white;
  cursor: pointer;
  border-width: initial;
  border-style: none;
  border-image: initial;
  position: relative;
  outline: none;
  font-weight: 500;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.03);
  border-radius: 48px;
  box-sizing: border-box;
  @include breakpoint-up(md) {
    padding: 0.8em 2em;
  }
  &:disabled {
    opacity: 0.8;
    cursor: default;
  }
  &:hover:not(:disabled) {
    background-color: darken($second-primary-color, 10%);
  }
  &:focus {
    outline: none;
  }
  .btnContent {
    @extend .d-flex-center;
    width: 100%;
    .btnChildren:only-child {
      @extend .d-flex-center;
      min-height: 32px;
    }
    svg {
      margin-bottom: -3px;
    }
    @include breakpoint-up(md) {
      width: 100%;
      max-height: 100%;
      height: 100%;
    }
  }
  .btnContentWithIcon {
    min-height: 34px;
    div:not(.btnChildren) {
      max-width: 50px;
    }
    div:first-child {
      margin-right: 5px;
    }
  }
}

.btnLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary {
  background-color: $second-primary-color;
}


.danger {
  background-color: $violet-red;
  &:hover:not(:disabled) {
    background-color: darken($violet-red, 8%);
  }
}

.outline {
  border: 1px solid $second-primary-color;
  background-color: white;
  color: $second-primary-color;
  &:hover:not(:disabled) {
    background-color: $second-primary-light-color;
  }
}

.primarySuccess {
  border: 1px solid $success-color;
  background-color: $success-color;
  &:hover:not(:disabled) {
    background-color: lighten($success-color, 3);
    color: white;
  }
}

.outlineSuccess {
  background-color: white;
  color: $success-color;
  border: 1px solid $success-color;
  svg path {
    fill: $success-color;
  }
  &:hover:not(:disabled) {
    background-color: lighten($success-color, 50%);
  }
}
