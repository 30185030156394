@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.section {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  .tableWrapper {
    background-color: white;
    padding: 10px 4px 24px 24px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    .divider {
      height: 1px;
      background-color: #f6f2ff;
      margin-bottom: 10px;
    }
  }
}

.infoContainer {
  text-align: justify;
  white-space: pre-line;
  line-height: 1.2rem;
  h1 {
    margin-top: 0;
  }
  @include breakpoint-up(md) {
    line-height: 1.8rem;
    text-align: left;
  }
}

.btnWrapper {
  text-align: right;
  margin-top: 30px;
  margin-bottom: 30px;
  button {
    width: 100%;
    svg path {
      fill: $second-primary-color;
    }
  }
  @include breakpoint-up(md) {
    button {
      width: revert;
    }
  }
}
