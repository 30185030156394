@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.toggleButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .toggleButton {
    color: $gray;
    padding: 4px 16px;
    background-color: white;
    box-shadow: none;
    border-radius: 9px;
    @include breakpoint-up(md) {
      &:first-child {
        margin-right: 16px;
      }
    }
    &:hover {
      background-color: $second-primary-light-color;
    }
  }
  .activeButton {
    border-color: $primary-color;
    pointer-events: none;
    color: $second-primary-color;
    svg path {
      fill: $violet-red !important;
      stroke: $violet-red !important;
    }
    &:hover {
      background-color: white;
      box-sizing: border-box;
      color: $primary-color;
    }
  }
}
