@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.potentialValues {
  width: 100%;
  margin-bottom: 16px;
  // margin-top: 16px;
  @include breakpoint-up(lg) {
    margin-bottom: 0;
  }
  .potentialItem {
    background-color: white;
    padding: 15px 30px 15px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 6px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    .info {
      width: 100%;
      .label {
        margin-top: 0;
        margin-bottom: 8px;
        @include breakpoint-up(md) {
          font-size: 18px;
          margin-bottom: 16px;
        }
      }
      .differenceValue {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 14px;
        margin-bottom: 8px;
        svg {
          width: 18px;
          height: 18px;
        }
        .value {
          margin: 0 0 8px;
        }
        .beforeAndAfterValues {
          display: flex;
          align-items: center;
          justify-content: space-between;
          vertical-align: middle;
          span {
            line-height: 18px;
          }
        }
        @include breakpoint-up(md) {
          font-size: 16px;
          margin-bottom: 16px;
          svg {
            width: 24px;
            height: 24px;
          }
          .beforeAndAfterValues span {
            font-size: 18px;
          }
          .value {
            font-size: 18px;
            margin: 0;
          }
        }
      }
      .barChart {
        background-color: $gray-light-color;
        border-radius: 10px;
        height: 15px;
        position: relative;
        .potentialValue {
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 10px;
        }
      }
    }
    .circle {
      margin-right: 20px;
      min-width: 70px;
      max-width: 70px;
      min-height: 70px;
      max-height: 70px;
      height: 70px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: white;
        max-width: 60%;
        path {
          fill: white;
        }
      }
    }
  }
}