.horizontalChartContainer {
  height: 25px;
  max-height: 15px;
  padding: 0;
  height: 100px;
  min-width: 100px;
  margin: 0 auto;
  .wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    .absoluteLeftElement {
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      z-index: 1;
      left: 0;
    }
    .absoluteElement {
      @extend .absoluteLeftElement;
      top: 0;
      bottom: 0;
      right: 0;
    }
    .colorFilling {
      border-radius: 2px;
      width: 100%;
      height: 100%;
    }
    .topStick {
      border-radius: 1px;
      height: 100%;
      width: 1px;
      background-color: black;
    }
    .centerStick {
      margin: auto 0;
      width: 100%;
      height: 1px;
      background-color: black;
    }
  }
}

.verticalChartContainer {
  width: 15px;
  max-height: 15px;
  height: 100px;
  min-height: 100px;
  margin: 0 auto;
  .wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    .absoluteBottomElement {
      z-index: 1;
      position: absolute;
      bottom: 0;
      flex-direction: column;
      width: 100%;
    }
    .absoluteElement {
      @extend .absoluteBottomElement;
      top: 0;
      left: 0;
      right: 0;
    }
    .colorFilling {
      border-radius: 2px;
      width: 100%;
      height: 100%;
    }
    .topStick {
      border-radius: 1px;
      width: 100%;
      height: 1px;
      background-color: black;
    }
    .centerStick {
      margin: 0 auto;
      height: 100%;
      width: 1px;
      background-color: black;
    }
  }
}
