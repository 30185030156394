@import 'styles/variables.scss';

.phoneFieldWrapper {
  .numberInput {
    input {
      border: 1px solid $gray-light-color;
      margin-left: 10px;
    }
  }
}
