@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $mobile-navbar-height;
  padding: 8px 0;
  z-index: $z-index-navbar;
  text-align: center;
  background-color: white;
  @include breakpoint-up(md) {
    padding: 16px 0;
    text-align: left;
    height: $navbar-height;
    background-color: $background-color;
  }
}

.navbarInner {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logoLinkContainer {
  position: relative;
  width: 130px;
}

.logo {
  max-height: 45px;
  width: 100%;
}

.navbarContent {
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  justify-content: flex-end;
  padding-right: 16%;
  @include breakpoint-up(md) {
    padding-right: 0;
  }
}

.hamburger {
  position: absolute;
  top: 22px;
  right: 30px;
  span {
    width: 20px;
    background-color: $second-primary-color;
  }
  @include breakpoint-up(md) {
    top: 25px;
    right: 25px;
  }
}

.loginBtn {
  div {
    height: 22px;
  }
  span:last-child {
    white-space: nowrap;
  }
}

.environment {
  position: absolute;
  right: 0;
  bottom: -2px;
  font-size: 0.7em;
  color: $primary-color;
}
