@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.card {
  position: relative;
  border: 1px solid $white-smoke-color;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  @include breakpoint-up(md) {
    padding: 20px;
  }
  .categoryTabs {
    display: flex;
    flex-wrap: wrap;
    .categoryTab {
      padding: 10px 18px;
      margin-right: 5px;
      margin-bottom: 5px;
      flex-grow: 1;
      @include breakpoint-up(md) {
        flex-grow: 0;
      }
    }

    .activeCategoryTab {
      color: $text-inverted-color;
      background-color: $second-primary-color;
      &:hover {
        color: rgba($text-inverted-color, 0.9) !important;
        background-color: darken($second-primary-color, 10%) !important;
      }
    }
  }
  .planOverviewBtn {
    button {
      width: 100%;
    }
    @include breakpoint-up(md) {
      text-align: right;
      button {
        width: initial;
      }
    }
  }
  .categoriesContainer {
    margin-bottom: 60px;
  }
}
