.overlay {
  padding-right: 18px;
  p {
    padding-left: 5px;
    margin: 8px;
  }
  .closeBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    outline: none;
  }
}
