@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.fieldWrapper {
  padding-bottom: 20px;
  padding-top: 25px;
  margin-bottom: 5px;
  position: relative;
  .note {
    text-align: left;
    margin-top: 10px;
  }
  .timeline {
    height: 65px;
    position: relative;
    margin-top: 15px;
    .labels {
      .optionLabel {
        outline: none;
        border: none;
        background-color: transparent;
        border-width: initial;
        border-style: none;
        border-image: initial;
        cursor: pointer;
        position: absolute;
        font-size: 11px;
        color: $gray-dark-color;
        transition: color 0.3s linear;
        padding: 4px 6px;
        border: 1px solid transparent;
        @include breakpoint-up(md) {
          font-size: 14px;
        }
        top: 0;
        &:nth-child(even) {
          top: 35px;
        }
      }
      .activeOptionLabel {
        transition: color 0.3s linear;
        border-radius: 4px;
      }
    }
    .lineContainer {
      position: absolute;
      top: 30px;
      width: 100%;
      height: 1px;
      background-color: $primary-color;
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        height: 20px;
        width: 1px;
        left: 0;
        bottom: -10px;
        background-color: $primary-color;
      }
      &::after {
        content: '';
        display: inline-block;
        border: solid $primary-color;
        position: absolute;
        right: 0;
        bottom: -4px;
        border-width: 0 1px 1px 0;
        padding: 4px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }
  .label {
    position: absolute;
    left: 0px;
    pointer-events: none;
    font-size: 16px;
    color: $primary-color;
    top: 9px;
  }
}

.primaryTimeline {
  .timeline .labels .activeOptionLabel {
    color: $primary-color;
    border: 1px solid $primary-color;
  }
}

.greenTimeline {
  .timeline .labels .activeOptionLabel {
    color: $success-color;
    border: 1px solid $success-color;
  }
}
