@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.projectName {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: $primary-color;
  margin: 0;
}

.disabledBtn {
  svg path {
    fill: #e56666;
  }
}

.projectCard {
  background: $text-inverted-color;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 10px 0;
  padding: 22px;
  position: relative;
  border: 1px solid white;
  @include breakpoint-up(md) {
    padding: 32px 32px 24px;
  }
  &:hover {
    border: 1px solid $second-primary-color;
    box-shadow: 12px 24px 40px rgba(0, 0, 0, 0.08);
    cursor: pointer;
  }
  .projectInfo {
    margin: 17px 0;
    .row {
      margin-top: 10px;
      .item {
        font-weight: 300;
        font-size: 14px;
        color: $gray-dark-color;
      }
      .currency {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000;
      }
    }
  }

  .divider {
    border: 1px solid #f6f2ff;
    box-shadow: 8px 16px 32px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-bottom: 16px;
    margin-top: 20px;
  }
}

.greenProjectCard {
  &:hover {
    border-color: $success-color;
  }
}

.activeProject {
  position: relative;
  border: 2px solid $second-primary-color;
  &:hover {
    border: 2px solid $second-primary-color;
  }
  .projectName {
    color: $second-primary-color;
  }
}

.greenActiveProject {
  position: relative;
  border: 2px solid $success-color;
  &:hover {
    border: 2px solid $success-color;
  }
  .projectName {
    color: $success-color;
  }
}

.projectsRow {
  margin-top: 20px;
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-self: space-between;
}

.buttonsWrapper {
  a,
  button {
    width: 100%;
  }
  & > a:first-child {
    button {
      margin-bottom: 10px;
    }
  }
  a[disabled] {
    pointer-events: none;
    button {
      pointer-events: none;
    }
  }
}
