@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.disclaimer {
  position: sticky;
  top: 80px;
  z-index: 3;
  padding: 10px 24px;
  a,
  button {
    width: 100%;
  }
  .disclaimerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .infoIcon {
      min-width: 40px;
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    @include breakpoint-up(lg) {
      flex-direction: row;
    }
    .btnWrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 16px;
      a:last-child {
        margin-top: 10px;
      }
      @include breakpoint-up(md) {
        width: initial;
        flex-wrap: nowrap;
        align-items: center;
        white-space: pre;
        margin-left: 20px;
        a:last-child {
          margin-top: 0;
          margin-left: 10px;
        }
      }
      @include breakpoint-up(lg) {
        margin-top: 0;
      }
    }
  }
}

.settingsIcon {
  position: absolute;
  top: 20px;
  right: 100px;
  cursor: pointer;
  z-index: 50;
  @include breakpoint-up(md) {
    top: 1rem;
    right: 1rem;
  }
}
.devMenu {
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 70vw;
  padding: 10px;
  background-color: $second-primary-light-color;
  z-index: 50;
  border-radius: 0 0 10px 10px;
  .closeIcon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    transform: rotate(-90deg);
    svg path {
      fill: $second-primary-color;
    }
  }
}
.devMenuOpen {
  transition: all 0.5s linear;
  transform: translateY(0%);
}
.devMenuHidden {
  transition: all 0.5s linear;
  transform: translateY(-100%);
}