@import 'styles/variables.scss';

.container {
  margin-top: 10% !important;
  .buttonWrapper {
    text-align: right;
    margin-top: 15px;
    button {
      svg path {
        fill: $second-primary-color;
      }
    }
  }
}
