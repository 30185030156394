.rc-table {
  font-size: 14px;
  color: black;
  line-height: 1.5;
  box-sizing: border-box;
  position: relative;
}
.rc-table-rtl {
  direction: rtl;
}
.rc-table table {
  border-spacing: 0px;
  width: 100%;
}
.rc-table th,
.rc-table td {
  padding: 0;
  position: relative;
  border: 1px solid #5f5f5f;
  border-top: 0;
  border-left: 0;
  transition: box-shadow 0.3s;
  padding: 12px 8px;
  box-sizing: border-box;
  white-space: normal;
  word-break: break-word;
}
.rc-table-rtl.rc-table th,
.rc-table-rtl.rc-table td {
  border-left: 1px solid #5f5f5f;
  border-right: 0;
}
.rc-table-cell-fix-left,
.rc-table-cell-fix-right {
  z-index: 1;
}
.rc-table-cell-fix-right:last-child:not(.rc-table-cell-fix-sticky) {
  border-right-color: transparent;
}
.rc-table-rtl .rc-table-cell-fix-right:last-child {
  border-right-color: #5f5f5f;
}
.rc-table-rtl .rc-table-cell-fix-left:last-child {
  border-left-color: transparent;
}
.rc-table-rtl .rc-table-cell-fix-left-first {
  box-shadow: 1px 0 0 #5f5f5f;
}
.rc-table-cell-fix-left-first::after,
.rc-table-cell-fix-left-last::after {
  pointer-events: none;
  content: '';
  transition: box-shadow 0.3s;
  position: absolute;
  top: 0;
  bottom: -1px;
  width: 20px;
  right: -1px;
  transform: translateX(100%);
}
.rc-table-cell-fix-right-first,
.rc-table-cell-fix-right-last {
  box-shadow: -1px 0 0 #5f5f5f;
}
.rc-table-rtl .rc-table-cell-fix-right-first,
.rc-table-rtl .rc-table-cell-fix-right-last {
  box-shadow: none;
}
.rc-table-cell-fix-right-first::after,
.rc-table-cell-fix-right-last::after {
  pointer-events: none;
  content: '';
  transition: box-shadow 0.3s;
  position: absolute;
  top: 0;
  bottom: -1px;
  width: 20px;
  left: -1px;
  transform: translateX(-100%);
}
.rc-table-cell.rc-table-cell-ellipsis {
  /* white-space: nowrap; */
  overflow: hidden;
  /* text-overflow: ellipsis; */
}
.rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-first,
.rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-last,
.rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-first .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-last {
  overflow: visible;
}
.rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-first .rc-table-cell-content,
.rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-last .rc-table-cell-content,
.rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-first .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-last .rc-table-cell-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.rc-table-ping-left .rc-table-cell-fix-left-first::after,
.rc-table-ping-left .rc-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px #e0e0e0;
}
.rc-table-ping-right .rc-table-cell-fix-right-first::after,
.rc-table-ping-right .rc-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px #e0e0e0;
}
.rc-table-expand-icon-col {
  width: 60px;
}
.rc-table-row-expand-icon-cell {
  text-align: center;
}
.rc-table thead td,
.rc-table thead th {
  background: #f7f7f7;
  text-align: center;
}
.rc-table thead .rc-table-cell-scrollbar::after {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: -1px;
  width: 1px;
  background: #f7f7f7;
}
.rc-table-rtl.rc-table thead .rc-table-cell-scrollbar::after {
  right: -1px;
  left: auto;
}
.rc-table-header {
  border: 1px solid #5f5f5f;
  border-right: 0;
  border-bottom: 0;
}
.rc-table-placeholder {
  text-align: center;
}
.rc-table tbody tr td,
.rc-table tbody tr th {
  background: #fff;
}
.rc-table-content {
  border-right: 0;
  border-bottom: 0;
  border-radius: 5px 0 0 0;
}
.rc-table-body {
  border: 1px solid #5f5f5f;
  border-right: 0;
  border-bottom: 0;
  border-top: 0;
}
.rc-table-fixed-column .rc-table-body::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-right: 1px solid #5f5f5f;
  z-index: 1;
}
.rc-table-expanded-row-fixed {
  box-sizing: border-box;
  margin: -16px -8px;
  padding: 16px 8px;
  margin-right: -10px;
}
.rc-table-expanded-row-fixed::after {
  content: '';
  position: absolute;
  width: 0;
  top: 0;
  bottom: 0;
  right: 1px;
  border-right: 1px solid #5f5f5f;
}
.rc-table-row-expand-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid currentColor;
  color: #aaa;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  line-height: 16px;
}
.rc-table-row-expand-icon.rc-table-row-expanded::after {
  content: '-';
}
.rc-table-row-expand-icon.rc-table-row-collapsed::after {
  content: '+';
}
.rc-table-row-expand-icon.rc-table-row-spaced {
  visibility: hidden;
}
.rc-table-title {
  border: 1px solid #5f5f5f;
  border-bottom: 0;
  padding: 16px 8px;
}
.rc-table-footer {
  border: 1px solid #5f5f5f;
  border-top: 0;
  padding: 16px 8px;
}
.rc-table tfoot td {
  background: #fff;
}
.rc-table-sticky-header {
  position: sticky;
  z-index: 2;
}
.rc-table-sticky-scroll {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  border-top: 1px solid #f3f3f3;
  opacity: 0.6;
  transition: transform 0.1s ease-in 0s;
  z-index: 2;
}
.rc-table-sticky-scroll:hover {
  transform: scaleY(1.2);
  transform-origin: center bottom;
}
.rc-table-sticky-scroll-bar {
  height: 8px;
  border-radius: 4px;
  background-color: #bbb;
}
.rc-table-sticky-scroll-bar:hover {
  background-color: #999;
}
.rc-table-sticky-scroll-bar-active {
  background-color: #999;
}
