@import "styles/variables.scss";

.greenCheckbox {
  :global(.rc-checkbox-checked) :global(.rc-checkbox-inner) {
    border-color: $success-color;
    background-color: $success-color;
  }
}

.checkboxWrapper {
  padding: 20px 0;
  position: relative;
  .checkbox {
    display: flex;
    margin-bottom: 5px;
    align-items: center !important;
    .label {
      cursor: pointer;
      padding: 0 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #5f5f5f;
      margin-bottom: 4;
      display: flex;
      align-items: center;
      & > div {
        text-align: left;
      }
      .icon {
        margin-left: 5px;
        margin-top: 5px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .checkboxInput {
      margin-bottom: 2px;
      & * {
        box-sizing: content-box !important;
      }
    }
  }
}

.note {
  text-align: left;
}
