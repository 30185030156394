@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.immazing {
  margin-bottom: 20px;
  font-size: 1rem;
  @include breakpoint-up(md) {
    font-size: 1.4rem;
  }
  p {
    color: $primary-color;
    margin: 16px 0;
  }
  .note {
    font-size: 1rem;
  }
}

.btnWrapper {
  text-align: right;
  button {
    width: 100%;
    @include breakpoint-up(md) {
      width: initial;
    }
  }
}
