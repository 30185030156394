@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.pageHeader {
  align-items: center;
  margin-bottom: 15px;
  @include breakpoint-up(md) {
    margin-bottom: 20px;
  }
}

.firstCol {
  h1 {
    text-align: left;
  }
}
.secondCol {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > button {
    & > div {
      width: max-content !important;
    }
    margin-left: 10px;
    @include breakpoint-up(md) {
      margin-left: 15px;
    }
  }
  button {
    path {
      fill: white;
    }
  }
}
