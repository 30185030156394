@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.container {
  margin-top: 40px;
  min-height: 75vh;
  display: flex;
  align-items: center;
  .row {
    justify-content: space-between;
    .form {
      order: 1;
      margin-top: 20px;
      @include breakpoint-up(md) {
        order: 1;
      }
    }
  }
}
