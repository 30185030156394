@import 'styles/variables.scss';

.fieldWrapperBigLabel {
  padding-top: 100px !important;
  .tooltip {
    top: 45px !important;
  }
}

.fieldWrapper {
  position: relative;
  padding-bottom: 20px;
  padding-top: 85px;
  margin-bottom: 5px;
  .sliderWrapper {
    padding: 0;
    .inputLabel {
      position: absolute;
      left: 0px;
      pointer-events: none;
      height: 32px;
      font-size: 16px;
      color: $primary-color;
      top: 5px;
    }
    .slider {
      -webkit-appearance: none;
      width: 100%;
      height: 8px;
      padding: 0;
      background-color: $second-primary-color;
      outline: none;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
      position: relative;
      border-radius: 6px;
      padding: 0;
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 30px;
        height: 30px;
        background-color: $yellow-color;
        border-radius: 50%;
        border: 3px solid white;
        cursor: pointer;
      }
    }
    .greenSlider {
      background-color: $success-color;
    }
    .marks {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: #999;
      margin-top: 10px;
    }
  }
  .tooltip {
    min-width: 100px;
    max-width: 210px;
    border: 1px solid #bda9ef;
    border-radius: 5px;
    position: absolute;
    top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s linear;
    .inputWrapper {
      position: relative;
      .numberInput {
        padding: 12px 22px 12px 10px;
        width: 115px;
      }
      .units {
        position: absolute;
        right: 5px;
        top: 14px;
        font-size: 14px;
        color: $gray-dark-color;
      }
    }
    .triangle {
      content: '';
      position: absolute;
      transition: all 0.1s linear;
      top: 100%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: $gray-dark-color transparent transparent transparent;
    }
  }
  .greenTooltip {
    border: 1px solid $success-color;
  }
}
