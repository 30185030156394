@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.title {
  font-weight: 600;
  color: $primary-color;
  text-align: center;
  font-size: 1.375rem;
  line-height: 1.68rem;
  @include breakpoint-up(md) {
    font-size: 2rem;
    line-height: 2.4rem;
    text-align: left;
  }
}
