@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.text {
  font-size: 16px;
  color: $gray;
  display: block;
  cursor: auto;
}

.contactByCheckboxesWrapper {
  display: flex;
  margin-bottom: 10px;
  div {
    &:first-child {
      padding-left: 0;
    }
    padding-left: 10px;
    padding-right: 10px;
  }
}

.inputsWrapper {
  display: flex;
  flex-direction: column;
  white-space: pre;
  @include breakpoint-up(md) {
    align-items: center;
    flex-direction: row;
  }
  .group {
    display: flex;
    align-items: center;
  }
}

.birthWrapper {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 12px;
  @include breakpoint-up(md) {
    margin-bottom: 0px;
  }
  .birthField {
    &:first-child {
      margin: 0;
    }
    margin-left: 6px;
    margin-right: 6px;
    padding-bottom: 25px;
    @include breakpoint-up(md) {
      width: 10%;
      min-width: 88px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.fieldWrapper {
  max-width: 80px;
  padding: 10px !important;
  margin-bottom: 0 !important;
}

.btnWrapper {
  margin-bottom: 10px;
  text-align: right;
  button {
    width: 100%;
  }
  @include breakpoint-up(md) {
    text-align: right;
    button {
      width: initial;
    }
  }
}

.additionalInformation {
  margin-top: 50px;
  @include breakpoint-up(md) {
    margin-top: 25px;
  }
}

.dateOfBirth {
  font-size: 16px;
  line-height: 19px;
  color: #5f5f5f;
  margin-bottom: 15px;
}
