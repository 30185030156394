@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.sidebarOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: $z-index-sidebar-overlay;
  left: 0;
  top: 0;
  animation: fadeIn ease-in-out 0.3s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sidebar {
  display: block;
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: $mobile-navbar-height;
  padding-bottom: 15px;
  height: 100%;
  width: $sidebar-width;
  z-index: $z-index-sidebar;
  transform: translateX(-$sidebar-width);
  transition: transform 0.3s ease-in-out;
  box-shadow: 4px 6px 24px rgba(0, 0, 0, 0.05);
  border-radius: 0px 32px 32px 0px;
  &.sidebarOpen {
    transform: translateX(0);
  }
  @include breakpoint-up(md) {
    transform: translateX(0);
    padding-top: 37px;
    padding-bottom: $footer-height;
  }
}

.sidebarContent {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  height: 98%;
  overflow-y: auto;
  @include breakpoint-up(md) {
    height: 94%;
    padding-top: 5px;
  }
}

.sidebarMenu {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 70px;
}

.sidebarMenuItem {
  padding-left: 5px;
  padding-right: 15px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: $gray;
  background-color: transparent;
  font-size: 1em;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 200px;
  margin: 10px auto;
  text-align: left;
  border-radius: 8px;
  &:hover {
    background-color: rgba($primary-color, 0.1);
  }
  &:focus {
    outline: none;
  }
}

.greenSidebarMenuItem {
  @extend .sidebarMenuItem;
}

.sidebarMenuItemIcon {
  font-size: 1.4em;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.sidebarMenuItemActive {
  color: $second-primary-color;
  &:after {
    position: absolute;
    content: ' ';
    height: 100%;
    left: 0;
    top: 0;
  }
  svg path {
    fill: $violet-red;
  }
}

.greenSidebarMenuItemActive {
  @extend .sidebarMenuItemActive;
  color: $success-color;
  svg path {
    fill: $success-color;
  }
}

.mainMenu {
  flex: 1 0 auto;
}

.footerMenu {
  padding-left: 35px;
  padding-right: 25px;
  position: fixed;
  bottom: 0;
  padding: 0 25px 20px 35px;
  background: white;
  padding-bottom: 20px;
  .divider {
    width: 95%;
  }
  & > div:first-child {
    margin-bottom: 18px;
  }
  width: 100%;
  border-bottom-right-radius: 32px;
}

.logoContainer {
  margin-left: 25px;
  .logo {
    cursor: pointer;
    width: 95%;
  }
}
.divider {
  margin-top: 21px;
  height: 1px;
  background-color: #f6f2ff;
}
