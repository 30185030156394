@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.container {
  .tableWrapper {
    order: 1;
    h2 {
      font-weight: 600;
      font-size: 24px;
      color: $primary-color;
    }
    b {
      color: $second-primary-color;
    }
    .table {
      background-color: white;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.03);
      border-radius: 6px;
      padding: 10px 24px;
    }
    @include breakpoint-up(md) {
      order: 0;
    }
  }

  .imgBox {
    position: sticky;
    top: 150px;
    margin: 20px 0;
  }

  .td {
    font-weight: 300;
  }

  .actionBar {
    display: flex;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .personalContribution {
    display: flex;
    justify-content: space-between;
    color: $gray;
    div:last-child {
      padding-left: 30px;
    }
  }
  .buttonsWrapper {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    svg path {
      fill: $second-primary-color;
    }
    a {
      width: 100%;
      margin: 5px 0;
    }
    @include breakpoint-up(lg) {
      a {
        width: initial;
      }
    }
  }
}
