@import 'styles/variables.scss';

.payersList {
  margin-bottom: 15px;
  margin-top: 0;
  padding-left: 0;
  .listItemBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    cursor: pointer;
    border-width: initial;
    border-style: none;
    border-image: initial;
    outline: none;
    width: 100%;
    color: $primary-color;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: white;
    &:first-child {
      margin-top: 0;
    }
    .payerAvatar {
      display: flex;
      align-items: center;
      .imgBox {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          border-radius: 50%;
        }
      }
      .name {
        margin-left: 10px;
      }
    }
  }
}
