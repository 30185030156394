@import 'styles/variables.scss';

.circleButton {
  border-width: initial;
  border-style: none;
  border-image: initial;
  position: relative;
  outline: none;
  padding: 0;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  font-size: 20px;
  color: $primary-color;
  border: none;
  border-radius: 4px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.default {
  background-color: white;
  &:hover {
    background-color: $second-primary-light-color;
  }
}

.secondary {
  background-color: $second-primary-light-color;
  &:hover {
    background-color: darken($second-primary-light-color, 5%);
  }
}

.primary {
  background-color: $second-primary-color;
  &:hover {
    background-color: darken($second-primary-color, 5%);
  }
}

.success {
  svg path {
    fill: $success-color !important;
  }
}

.defaultSuccess {
  @extend .success;
  background-color: white;
  &:hover {
    background-color: lighten($success-color, 50%);
  }
}
.primarySuccess {
  background-color: $success-color;
  &:hover {
    background-color: $success-color;
  }
}
.secondarySuccess {
  @extend .success;
  background-color: lighten($success-color, 50%);
  &:hover {
    background-color: lighten($success-color, 45%);
  }
}