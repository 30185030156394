@import 'styles/variables.scss';

.co2ParametersTable {
  margin-bottom: 24px;
  border-collapse: collapse;
  width: 100%;
  color: $primary-color;
  thead tr th {
    text-transform: uppercase;
    background-color: $second-primary-light-color;
    color: $primary-color;
  }
  
  thead th, tbody td {
    cursor: default;
    padding: 15px;
    border: 1px solid $bright-gray;
    background-color: white;
    color: $primary-color;
  }
}