@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 97vh;
  .logoContainer {
    margin-top: 24px;
    min-height: 40px;
    max-width: 150px;
    max-height: 50px;
    .logo {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }
  .card {
    padding: 3%;
    margin: 10px auto 0;
    @include breakpoint-up(md) {
      margin: 40px auto 60px;
      min-height: 565px;
      max-height: 700px;
      max-width: 650px;
      background-color: white;
      box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.03);
      width: fit-content;
      border-radius: 16px;
    }
    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: auto;
      .tick {
        width: 110px;
        height: 110px;
        background: #f0faf0;
        border-radius: 50%;
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 25%;
          left: 26%;
          width: 55px;
          height: 30px;
          border-left: 5px solid $success-color;
          border-bottom: 5px solid $success-color;
          transform: rotate(-45deg);
        }
      }
      .title {
        text-align: center;
        text-transform: uppercase;
      }
      .description {
        margin: 6px 0;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $gray;
      }
      .btnContainer {
        margin: 16px 0;
        & > button {
          width: 100%;
          box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.03);
          &:first-child {
            margin-bottom: 10px;
          }
          @include breakpoint-up(md) {
            width: initial;
            &:first-child {
              margin-right: 24px;
              margin-bottom: 0;
            }
          }
        }
      }
      .message {
        margin-bottom: 15px;
        color: $second-primary-color;
        width: 90%;
      }
    }
  }
}
