@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.container {
  text-align: center;
  margin-bottom: 15%;
  .subtitle {
    font-size: 20px;
  }
  .description {
    font-size: 1rem;
    line-height: 18px;
    color: #6f6969;
  }
  .projectCard {
    margin: 1rem 0;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    svg {
      max-width: 200px;
    }
    .startBtnWrapper {
      width: 100%;
      button {
        flex: 1;
        min-width: 180px;
      }
    }
    h3 {
      font-weight: bold;
    }
  }
  @include breakpoint-up(md) {
    text-align: left;
    margin-bottom: 0;
    .projectCard {
      margin: 0;
      .subtitle {
        font-size: 24px;
      }
      .btnWrapper {
        justify-content: center;
        button {
          flex: 0;
        }
      }
    }
  }
}

.submitBtn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.checkboxStyles {
  position: relative;
  & > div {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
