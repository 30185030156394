@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.cropImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .toolsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 15px 0;
    width: 100%;
    .slider {
      margin-right: 15px;
    }
    .arrowsContainer {
      display: flex;
      align-items: center;
      margin-right: 15px;
      .leftArrow {
        cursor: pointer;
        margin-right: 10px;
      }
      .rightArrow {
        cursor: pointer;
        transform: rotateY(180deg);
      }
    }
  }
  .btnWrapper {
    button {
      width: 100%;
    }
    @include breakpoint-up(md) {
      button {
        width: initial;
      }
    }
  }
}

.imgBox {
  position: relative;
  background-size: cover;
  object-fit: cover;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  cursor: pointer;
  &:hover {
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
  }
}

.dropzone {
  width: 80px;
  height: 80px;
  min-width: 70px;
  border-radius: 50%;
  background-color: #f7f7f7;
  border: 1px solid #ebebeb;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 15px;
  position: relative;
  .addAvatarIcon {
    margin-left: 2px;
    margin-bottom: 2px;
  }
}

.editButton {
  border: 2px solid white;
  position: absolute;
  bottom: 10px;
  right: -10px;
  background-color: $second-primary-color;
  border-width: initial;
  border-style: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: darken($second-primary-color, 5%);
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
  }
  svg {
    width: 80%;
    height: 80%;
    path {
      fill: white;
    }
  }
}

ul.editAvatarMenu {
  border: 1px solid $second-primary-light-color;
  li.menuItem {
    text-align: center;
    cursor: pointer;
    padding: 0;
    &:hover:not(:disabled) {
      background-color: $second-primary-light-color;
    }
    border-bottom: 1px solid $bright-gray;
    button {
      background-color: transparent;
      border-image: none;
      outline: none;
      border-width: initial;
      border-style: none;
      cursor: pointer;
      height: 100%;
      width: 100%;
      padding: 12px 20px;
      font-family: $font-family;
      color: $primary-color;
      font-size: 14px;
    }
  }
  li.deleteAvatarMenuItem {
    @extend .menuItem;
    button {
      color: $violet-red;
    }
  }
}