@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.dateOfBirth {
  text-align: left;
  font-size: 16px;
  line-height: 19px;
  color: $primary-color;
  margin-bottom: -2px;
  pointer-events: none;
}

.birthWrapper {
  margin-bottom: 25px;
  .numberInputWrapper {
    width: 100%;
  }
}
