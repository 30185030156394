@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.houseEnergySection {
  margin-bottom: 24px;
  .energyFigures {
    background-color: white;
    padding: 28px 16px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 16px;
    margin-top: 15px;
    .label {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 500;
      margin-top: 0;
      font-size: 16px;
    }
    .heatingDemandValue {
      display: block;
      font-size: 24px;
      margin: 16px 0;
    }
    .btnWrapper {
      margin-top: 40px;
    }
    @include breakpoint-up(md) {
      .label {
        font-size: 16px;
      }
      .heatingDemandValue {
        margin: 36px 0;
      }
    }
    @include breakpoint-up(lg) {
      text-align: left;
      margin-bottom: 0;
      text-align: center;
      margin-top: 0;
    }
    @include breakpoint-up(xl) {
      .label {
        font-size: 20px;
      }
    }
  }
}

.withoutGrid {
  thead tr th,
  tbody tr td {
    border: none;
  }
  th:empty {
    display: none;
  }
  .actualEnergyClass {
    height: 40px;
    width: 75%;
    line-height: 16px;
    .triangle {
      left: -19px;
      border-right: 19px solid $primary-color;
      border-top: 20px solid transparent !important;
      border-bottom: 20px solid transparent !important;
    }
  }
}

.energyScaleClassesContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  background-color: white;
  border-radius: 6px;
  padding: 10px 10px 25px;
  position: relative;
  .co2Tax {
    position: absolute;
    background-color: $success-color;
    padding: 10px 20px;
    color: white;
    font-weight: bold;
    border-radius: 6px;
    top: 15px;
    right: 20px;
    transform: rotate(15deg);
    max-width: 140px;
    text-align: center;
  }
  @include breakpoint-up(md) {
    padding: 25px 10px;
    .co2Tax {
      top: 30px;
    }
  }
  .energyScaleTable {
    width: 100%;
    margin-top: 20px;
    td,
    th {
      &:last-child {
        border-right: none;
        cursor: default;
      }
    }
    thead tr th {
      background-color: white;
      font-size: 12px;
      padding: 5px;
      @include breakpoint-up(md) {
        font-size: 19px;
        line-height: 23px;
      }
    }
    tbody tr td {
      border-collapse: collapse;
      color: white;
      padding: 0;
      cursor: default;
      .energyClass {
        padding: 5px;
        font-size: 22px;
        font-weight: 600;
        position: relative;
        margin-top: -1px;
        .triangle {
          position: absolute;
          display: inline-block;
          right: -18px;
          top: 0;
          width: 0;
          border-left: 18px solid $primary-color;
          border-top: 22px solid transparent !important;
          border-bottom: 22px solid transparent !important;
          border-right: none;
        }
        @include breakpoint-up(lg) {
          font-size: 18px;
          .triangle {
            border-top: 19px solid transparent !important;
            border-bottom: 19px solid transparent !important;
          }
        }
        @include breakpoint-up(xl) {
          font-size: 22px;
          .triangle {
            border-top: 22px solid transparent !important;
            border-bottom: 22px solid transparent !important;
          }
        }
      }
    }
    @include breakpoint-up(md) {
      margin-top: 0;
    }
  }
}

.actualEnergyClass {
  width: 60%;
  height: 24px;
  margin: auto;
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0px;
  .label {
    display: none;
  }
  .triangle {
    position: absolute;
    display: inline-block;
    left: -12px;
    width: 0;
    border-right: 12px solid $primary-color;
    border-top: 12px solid transparent !important;
    border-bottom: 12px solid transparent !important;
  }
  @include breakpoint-up(md) {
    height: 20px;
    max-width: 100px;
    .label {
      display: block;
      text-transform: uppercase;
    }
    .triangle {
      left: -9px;
      border-right: 9px solid $primary-color;
      border-top: 10px solid transparent !important;
      border-bottom: 10px solid transparent !important;
    }
  }
}
