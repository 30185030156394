@import 'styles/variables.scss';

.triggeredContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $primary-color;
  background-color: white;
  cursor: pointer;
  border-width: initial;
  border-style: none;
  border-image: initial;
  position: relative;
  outline: none;
  .imgPlaceholder {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    img {
      border-radius: 50%;
    }
  }
  .fullName {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 15px;
    padding-right: 10px;
    white-space: pre-line;
    span {
      text-transform: capitalize;
    }
  }
  .arrowDropUp {
    margin-left: auto;
  }
  &:hover {
    color: $second-primary-color;
    .arrowDropUp path {
      fill: $second-primary-color;
    }
  }
}

.menu {
  width: 270px;
  background-color: white;
  border-radius: 10px;
  list-style-type: none;
  box-shadow: 6px 6px 48px rgba(0, 0, 0, 0.08);
  text-align: center;
  padding: 20px 10px;
  box-sizing: border-box;
  outline: none;
  position: relative;
  .editButton {
    position: absolute;
    top: 60px;
    right: 95px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $second-primary-color;
    transition: background-color 0.3s ease-in;
    border-width: initial;
    border-style: none;
    border-image: initial;
    outline: none;
    &:hover {
      background-color: darken($second-primary-color, 5%);
    }
    svg {
      width: 80%;
      height: 80%;
      path {
        fill: white;
      }
    }
  }
  .menuItemImg {
    width: 64px;
    height: 64px;
    margin: 0 auto 16px;
    img {
      border-radius: 50%;
    }
  }
  .name {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #333333;
    margin-bottom: 8px;
  }
  .email {
    font-weight: 300;
    font-size: 16px;
    color: $gray-dark-color;
    margin-bottom: 16px;
  }
  .divider {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: $gray-light;
  }
  .btnMenuItem {
    button {
      border: none;
      width: 100%;
      border-radius: 5px;
      min-height: 40px;
      box-shadow: none;
    }
  }
  .btnDeleteAccount {
    @extend .btnMenuItem;
    button {
      border: none;
    }
  }
}

.btnWrapper {
  text-align: center;
}
