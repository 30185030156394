@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.localesContainer {
  margin-left: 8px;
  height: 100%;
  position: relative;
  display: flex;
  padding-top: 2px;
  max-height: 20px;
  .localeItem {
    border-image: initial;
    outline: none;
    border: none;
    background-color: transparent;
    border-width: initial;
    border-style: none;
    border-image: initial;
    cursor: pointer;
    color: $gray;
    text-transform: uppercase;
    padding: 0px 5px;
    font-size: 1rem;
    &:first-of-type {
      padding-left: 0;
      border-right: 1px solid $gray;
    }
  }
  .activeLocaleItem {
    color: $second-primary-color;
    transition: 0.3s color ease-in;
  }
}